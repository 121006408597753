


































































import Vue from 'vue';
import { Category } from '../../../shared/src/entities/Category';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import ConfirmDialog from '../components/ConfirmDialog.vue';

export default Vue.extend({
  inject: {
    categoryDataservice: 'categoryDataservice'
  },
  data() {
    return {
      headers: [
        { text: 'Billede', value: 'image', sortable: false, filterable: false },
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status.name' },
        { text: 'Sortering', value: 'sortIndex' },
        { text: 'Oprettet', value: 'createdTimeLocalized' },
        { text: 'Id', value: 'id' },
        { text: 'Rediger', value: 'action', sortable: false, filterable: false }
      ],
      search: '',
      showSearch: false,
      selectedCategories: [] as Category[],
      max25chars: (v: any) => v.length <= 25 || 'Input too long!'
      };
    },
    components: {
      ConfirmDialog,
    },
    methods: {
      editItem(category: Category) {
        this.$router.push({ name: 'Category', params: { id: category.id } });
      },
      async createCategory(): Promise<void> {
        const categoryDataservice = ((this as any).categoryDataservice as ICategoryDataservice);
        const categoryId = await categoryDataservice.addCategory();
        this.$router.push({ name: 'Category', params: { id: categoryId } });
      },
      deleteCategories(): void {
        (this.$refs.confirmDialog as any).show('Er du sikker på at du vil slette ' +
          this.selectedCategories.length + ' kategorier',
          this.acceptDeleteCategories);
      },
      acceptDeleteCategories(): void {
          const deleteCategories: Array<Promise<any>> = [];
          this.selectedCategories.forEach((category) => {
            deleteCategories.push(((this as any).categoryDataservice as ICategoryDataservice).deleteCategory(category.id));
          });
          Promise.all( deleteCategories ).then(() => {
            this.selectedCategories = [];
        });
      }
    },
    watch: {
    }
});
